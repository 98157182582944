<template>
    <div class="contain fl_r" @mouseleave="mouseleave" @mouseenter="mouseenter">
        <div class="containSmall al_c ju_c fl_c" :style="{'right': (showBig ? '236px':'')}">
            <span>下</span>
            <span>载</span>
            <span>中</span>
            <span>心</span>
        </div>
        <div class="containBig" v-if="showBig">
            <div class="pad_around fl_c">
                <div class="title">交易软件</div>
                <div class="pad_left">
                    <div class="whichText" style="margin-top: 30px">
                        电脑客户端：
                    </div>
                    <div class="downloadText">
                        Windows客户端下载
                    </div>
                </div>
                <div class="pad_left">
                    <div class="whichText" style="margin-top: 30px">
                        手机端：
                    </div>
                    <div class="downloadText" @click="downloadNow">
                        客户端下载
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getDownload} from "../../utils/api";

    export default {
        name: "greenAsider",
        components: {},
        data() {
            return {
                showBig: false,
                downloadList: [],
            }
        },
        mounted() {
            this.getDownload()
        },
        methods: {
            downloadNow() {
                location.href = this.downloadList[1].file1
            },
            mouseenter() {
                this.showBig = true
            },
            mouseleave() {
                this.showBig = false
            },
            async getDownload() {
                let res = await getDownload()
                this.downloadList = res.data
            },
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        position: relative;
    }

    .containSmall {
        position: fixed;
        right: 0px;
        top: 100px;
        float: right;
        width: 30px;
        height: 150px;
        color: white;
        background-color: #03920a;
        cursor: pointer;
        margin-top: 50px;
        border-radius: 5px 0 0 5px;

        span {
            padding: 2px 0;
            font-size: 13px;
        }
    }

    .containBig {
        position: fixed;
        right: 0px;
        top: 100px;
        float: right;
        width: 236px;
        height: 750px;
        background-color: #03920a;
        z-index: 9;
        .title {
            font-size: 18px;
            font-weight: bold;
            color: white;

        }

        .whichText {
            color: white;
            font-size: 14px;
        }

        .downloadText {
            color: white;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
        }
    }
</style>
