<template>
    <div>
        <div class="contain fl_c al_c ju_c" v-if="!$common.mobile()">
            <div class="fl_r">
                <div class="grayText fl_r al_c">
                    <!--                    <span @click="goPage">滇ICP备2023001316号-3&nbsp;&nbsp;</span>-->
                    <!--                    <img src="@/assets/img/ghs.png" alt="">-->
                    <!--                    <span @click="goPage1">滇公网安备&nbsp;&nbsp;53342102000063</span>-->
                    <span @click="goPage">滇ICP备2023001316号-4&nbsp;&nbsp;</span>
                    <img src="@/assets/img/ghs.png" alt="">
                    <span @click="goPage1">滇公网安备&nbsp;&nbsp;53342102000065</span>
                </div>
            </div>

            <div>云品农销交易中心 版权所有</div>
        </div>
        <div class="contain fl_c al_c ju_c" v-if="$common.mobile()">
            <div class="fl_r">
                <div class="grayText fl_c al_c">
                    <!--                    <span>滇ICP备2023001316号-3&nbsp;&nbsp;</span>-->
                    <!--                    <div class="fl_r al_c">-->
                    <!--                        <img src="@/assets/img/ghs.png" alt="">-->
                    <!--                        <span>网安备案号&nbsp;&nbsp;53342102000063</span>-->
                    <!--                    </div>-->
                    <span>滇ICP备2023001316号-4&nbsp;&nbsp;</span>
                    <div class="fl_r al_c">
                        <img src="@/assets/img/ghs.png" alt="">
                        <span>网安备案号&nbsp;&nbsp;53342102000065</span>
                    </div>
                </div>
            </div>

            <div @click="goPage" style="cursor: pointer">
                云品农销交易中心 版权所有
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        components: {},
        data() {
            return {
                arr: [
                    {
                        id: 1,
                        text: '中心简介',
                    },
                    {
                        id: 2,
                        text: '中心资质',
                    },
                    {
                        id: 3,
                        text: '营业执照',
                    },
                    {
                        id: 4,
                        text: '政策支持',
                    },

                ],
            }
        },
        mounted() {

        },
        methods: {
            goPage() {
                window.open('https://beian.miit.gov.cn', '_blank')
            },
            goPage1() {
                window.open('https://www.beian.gov.cn/portal/registerSystemInfo', '_blank')
            },
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        width: 100%;
        height: 78px;
        position: relative;
        bottom: 0;
        border-top: 3px solid #195B40;
    }

    .box {
        width: 75%;
        height: 100%;
        margin: 0 auto;
    }

    .topText {
        padding: 30px 35px 20px;
        color: white;
        font-size: 13px;
    }

    .line {
        width: 40%;
        height: 2px;
        background-color: white;
        margin: 10px 0;
    }

    .grayText {
        color: #787878;
        font-size: 12px;

        span {
            cursor: pointer;
        }

        img {
            margin: 0 5px;
        }
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .whiteText {
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
    }
</style>
