<template>
    <div class="contain" :style="{'height': (!$common.mobile() ? '':'100%')}">
        <div class="pc_pic" v-if="!$common.mobile()">
            <el-carousel arrow="never" trigger="click" >
                <el-carousel-item  v-for="item in columnImg" :key="item.id" >
                    <img height="100%" width="100%" :src="item.image" alt="" style="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div  class="phone_pic"
                v-if="$common.mobile()" style="margin-top: 50px">
            <el-carousel arrow="never" trigger="click" >
                <el-carousel-item  v-for="item in columnImg" :key="item.id" >
                    <img height="100%" width="100%" :src="item.image" alt="" style="">
                </el-carousel-item>
            </el-carousel>
        </div>
<!--        <img class="contain1" :src="columnImg[0].image" alt="">-->
        <div class="tab" v-if="!$common.mobile()">
            <div class="middleBox ju_b fl_r">
                <div class="itemLeft fl_r">
                    <router-link class="fontSty" to="/">首页</router-link>
                    <div class="roundSty" v-if="!ifChild">⊙</div>
                    <div class="roundSty" v-else>>></div>
                    <router-link class="fontSty" :to="formalItem.router">{{formalItem.name}}</router-link>
                    <div class="roundSty" v-if="ifChild&&currentText!=''">>></div>
                    <div class="fontSty" v-if="ifChild">{{currentText}}</div>
                </div>
                <div class="itemRight fl_r al_c ju_c" v-if="showSecond">
                    <div :class="['header_item', current == item.id ? 'active' : 'deactive']"
                         v-for="item in formalItem.subArticles" :key="item.id" @click="chooseOne(item)">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="tab" v-if="$common.mobile()">
            <div class="middleBox1 fl_r" v-if="showSecond">
                <div :class="['header_item', current == item.id ? 'active' : 'deactive']"
                     v-for="item in formalItem.subArticles" :key="item.id" @click="chooseOne(item)">
                    {{item.name}}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "CenterTab",
        components: {},
        props: ['formalItem','columnImg'],
        data() {
            return {
                current: 0,
                currentText: '',
                ifChild: false,
                showSecond:true,
            }
        },
        watch:{
            formalItem(newValue,oldValue){
                    this.current=0
                    this.currentText=''
                if(this.formalItem.id==5||this.formalItem.id==6){
                    this.showSecond=false
                }else{
                    this.showSecond=true
                }
                // sessionStorage.removeItem("currentSecondType");
            }
        },
        mounted() {
            if(this.formalItem.id==5||this.formalItem.id==6){
                this.showSecond=false
            }else{
                this.showSecond=true
            }
            if(sessionStorage.getItem('currentSecondType')){
                this.current=sessionStorage.getItem('currentSecondType').split(',')[0]
            }

        },
        methods: {
            chooseOne(item) {
                let id=sessionStorage.getItem('current')
                if(id==2){
                    this.$router.push({
                        path: "/ecologicalProducts",
                        query: {
                            secondId:item.id
                        }
                    });
                }else{
                    this.$router.push('listPage')
                }

                this.ifChild = true
                this.current = item.id
                this.currentText = item.name
                sessionStorage.setItem('currentSecondType',[item.id,item.name])
                this.$store.commit('setSecondType',item.id);
            },
        },
    }
</script>

<style scoped lang="scss">
    .pc_pic{
        .el-carousel {
            z-index: 0;
            width: 100%;
            height: 510px;
            position: relative;
        }
        /deep/ .el-carousel__container{
            height: 510px;
        }
        /*.el-carousel__item {*/
        /*    width: 100%;*/
        /*    position: relative;*/
        /*}*/
        /deep/ .el-carousel__button {
            /*width: 16px;*/
            /*height: 16px;*/
            /*border-radius: 50%;*/
            /*margin: 0 10px;*/
            display: none;
        }

        /deep/ .el-carousel__indicators--horizontal {
            bottom: 70px;
        }
    }

    .phone_pic{
        /deep/ .el-carousel__button {
            /*width: 16px;*/
            /*height: 16px;*/
            /*border-radius: 50%;*/
            /*margin: 0 10px;*/
            display: none;
        }
    }
    .contain {
        width: 100%;
        height: 510px;
        position: relative;
        margin-top: -96px;
    }
    .contain1 {
        width: 100%;
        height: 510px;
        position: relative;
    }
    .tab {
        position: absolute;
        width: 100%;
        height: 52px;
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 5px;
        z-index: 9;
        .middleBox {
            width: 63%;
            height: 52px;
            margin: 0 auto;
            .itemLeft {
                width: 50%;
                height: 52px;
                .fontSty {
                    line-height: 52px;
                    color: #D9D9D9;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                }

                .roundSty {
                    line-height: 52px;
                    color: #D9D9D9;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    padding: 0 10px;
                }

                .fontSty:hover {
                    text-decoration: underline
                }
            }

            .itemRight {
                width: 50%;
                height: 52px;
            }

        }
    }

    .header_item {
        width: 25%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #ffffff;
        font-weight: bold;
        font-size: 15px;
        cursor: pointer;
        padding: 0 20px;
    }

    .active {
        color: #ffffff;
        font-weight: normal;
        font-style: normal;
        font-family: Microsoft YaHei;
        height: 32px;
        line-height: 32px;
        background-color: #d5b345;
        border-radius: 5px;
    }

    .deactive {
        color: #d5b345;
        font-weight: normal;
        font-style: normal;
        font-family: Microsoft YaHei;
    }

    .deactive:hover {
        color: #ffffff;
        font-weight: normal;
        font-style: normal;
        font-family: Microsoft YaHei;
        height: 32px;
        line-height: 32px;
        background-color: #d5b345;
        border-radius: 5px;
    }
    .middleBox1{
        width: 100%;
        justify-content: center;
        .header_item{
            width: auto;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: #ffffff;
            font-weight: bold;
            font-size: 12px;
            cursor: pointer;
            padding: 0 20px;
        }
    }
</style>
