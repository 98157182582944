import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        formalItem: {},
        childItem:{},
        isweixin:false,
    },
    mutations: {
        setFormalItem(state,data) {
            // 增加一个mutations的方法，方法的作用是让num从0变成5，state是必须默认参数
            state.formalItem = data;
        },
        setChildItem(state,data) {
            // 增加一个mutations的方法，方法的作用是让num从0变成5，state是必须默认参数
            state.childItem = data;
        },
        setWeixing(state,data){
          state.isweixin=data
        },
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            paths: ["formalItem","childItem","isweixin"]
        })
    ],
});

export default store;
