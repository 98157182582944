import axios from 'axios';
import { Loading } from 'element-ui';
import { RSAencrypt } from '@/utils/aesUtils.js'
let key ='MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCv3gFCCbyYW0EdGPrhdx/Be1B4afW61X5vE7h9NM4PUk4WQIPWqq2RDo7smmw+ZnqQQdyjLNkITxkh7DLJlh6twtiCUHi03i5zd/T7jcisv1e5Hap9IcM2sYS/cGAlRYN5qnQ5RizTJ1BpAsCocxKTHD+4lxsptYpEZRFcd+cPQwIDAQAB'
const service = axios.create({

    // 基础的请求地址
    baseURL: process.env.VUE_APP_BASE_API,
    // 设置超时时间 5s
    timeout: 5000,
    // 让请求在浏览器中允许跨域携带cookie,若后台配置了跨域，还报，则改成false
    withCredentials: false,
    // 在向服务器发送前，修改请求数据
    transformRequest: [(data, headers) => {
        headers["Content-Type"] = 'application/json;charset=utf-8'
        headers["sdkcode"]= RSAencrypt(JSON.stringify({
            timestamp: new Date().getTime(),
            var: '1.0'
        }), key)
        return JSON.stringify(data)
    }]
});

// 请求拦截器，例如请求的时候在头部加上请求的token
service.interceptors.request.use(config => {
    if (config.toast) {
        Loading.service({ fullscreen: true })
        // Loading.service({
        //     lock: true,
        //     text: '加载中',
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // });
    }
    // //这个可以放获取到的token放在请求头
    // if (config.isToken) {
    //     config.headers["token"] = store.state.token
    // }
    return config; //  有且必须有一个config对象被返回
});

// 响应拦截器,例如判断服务器返回的状态，400，500啥的，其实超时可以写到这里面来，
service.interceptors.response.use(
    async res => {
        // loading.close();
        // 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
        if (res.status === 200) {
            if (res.data.code == '0000') {
                return Promise.resolve(res.data);
            } else {
                // Notify({type: 'danger', message: res.data.data || res.data.code + '消息为空'})
                return Promise.resolve(res.data);
            }
        } else {
            return Promise.reject(res);
        }
    },
    error => {
        // 用户信息失效，重新登录，或者其他问题可以再次设置全局的回应
        return Promise.reject(error)
    }
);

export default service;
