<template>
    <div id="app">
        <Header  v-if="!this.$store.state.isweixin"/>
        <green-asider v-if="!$common.mobile()" />
        <blue-asider v-if="!$common.mobile()" />
        <red-asider v-if="!$common.mobile()" />
        <router-view/>
        <Footer v-if="!this.$store.state.isweixin" />
    </div>
</template>

<script>
    import Header from "./components/Header/Header";
    import Footer from "./components/Footer/Footer";
    import greenAsider from "./components/asiders/greenAsider";
    import blueAsider from "./components/asiders/blueAsider";
    import redAsider from "./components/asiders/redAsider";
    export default {
        name: 'App',
        components: {
            Footer,
            Header,
            greenAsider,
            blueAsider,
            redAsider
        },
        data() {
            return {
                windowWidth: null,
            }
        },
        mounted() {
            this.$common.mobile()
            this.windowWidth=document.documentElement.clientWidth
        },
    }
</script>
<style lang="scss">
    @import "./assets/css/initialization.scss";
</style>
<style>
    #app {
        width: 100%;
        height: 100%;
        /*background: #f3f3f3;*/
    }

</style>
