<template>
    <div class="contain fl_r" @mouseleave="mouseleave" @mouseenter="mouseenter">
        <div class="containSmall al_c ju_c fl_c" v-if="!showBig" :style="{'right': (showBig ? '236px':'')}">
            <span>联</span>
            <span>系</span>
            <span>我</span>
            <span>们</span>
        </div>
        <div class="containBig" v-if="showBig">
            <img v-if="imgBox!=null" :src="imgBox.image" style="width: 100%;" alt="">
<!--            <div class="pad_around fl_c">-->
<!--                <div class="pad_left">-->
<!--                    <div class="fl_r al_c">-->
<!--                        <img src="@/assets/img/tel.png" class="imgSty" style="margin-top: 45px" alt="">-->
<!--                        <div class="fl_c">-->
<!--                            <div class="whichText" style="margin-top: 30px">-->
<!--                                客服电话：-->
<!--                            </div>-->
<!--                            <div class="downloadText">-->
<!--                                400-0521-566-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="pad_left">-->
<!--                    <div class="fl_r">-->
<!--                        <img src="@/assets/img/wx.png" class="imgSty" style="margin-top: 60px" alt="">-->
<!--                        <div class="fl_c">-->
<!--                            <div class="whichText" style="margin-top: 30px">-->
<!--                                客服微信：-->
<!--                            </div>-->
<!--                            <div class="downloadText">-->
<!--                                <img src="" style="width: 90px;height: 90px;background: white" alt="">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

        </div>
    </div>
</template>

<script>
    import {getDownload, imgInfoByType} from "../../utils/api";

    export default {
        name: "blueAsider",
        components: {},
        data() {
            return {
                showBig: false,
                downloadList: [],
                imgBox:null,
            }
        },
        mounted() {
            // this.getDownload()
            this.imgInfoByType()
        },
        methods: {
            async imgInfoByType(){
                let res = await imgInfoByType({
                    belongType:93
                })
                this.imgBox=res.data[0]
            },
            downloadNow() {
                location.href = this.downloadList[1].file1
            },
            mouseenter() {
                this.showBig = true
            },
            mouseleave() {
                this.showBig = false
            },
            async getDownload() {
                let res = await getDownload()
                this.downloadList = res.data
            },
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        position: relative;
    }

    .containSmall {
        position: fixed;
        right: 0px;
        top: 300px;
        float: right;
        width: 30px;
        height: 150px;
        color: white;
        background-color: #015aa8;
        cursor: pointer;
        margin-top: 50px;
        border-radius: 5px 0 0 5px;

        span {
            padding: 2px 0;
            font-size: 13px;
        }
    }

    .containBig {
        position: fixed;
        right: 0px;
        top: 100px;
        float: right;
        width: auto;
        height: auto;
        /*background-color: #015aa8;*/
        z-index: 9;
        .title {
            font-size: 18px;
            font-weight: bold;
            color: white;

        }

        .whichText {
            color: white;
            font-size: 14px;
            font-weight: bold;
        }

        .downloadText {
            color: white;
            font-size:18px;
            padding: 10px 0;
        }
    }
    .imgSty{
        width: 32px;
        height: 32px;
        margin-right: 20px;
    }
</style>
